import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Layout from '~/components/Layout';
import Metadata from '~/components/Metadata';
import ContentPage from '~/components/Content/Page';

type PageContext = {
  allActivities: { title: string; slug: string }[];
  slug: string;
  primaryColor: string;
  secondaryColor: string;
};

type ActivityPageProps = PageProps<{ page: Queries.ContentPageFragmentFragment }, PageContext>;

const ActivityPageTemplate = ({ data, pageContext }: ActivityPageProps) => {
  return (
    // @ts-ignore
    <Layout navColor={data.page.navColor}>
      <Metadata />
      <ContentPage page={data.page} pageContext={pageContext} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query ActivityPage($slug: String!) {
    page: contentfulPage(slug: { eq: $slug }) {
      ...ContentPageFragment
    }
  }
`;

export default ActivityPageTemplate;
